import HBEventBusService from "../../../../services/hbBusService";

const voltran = require("/home/node/app/node_modules/voltranjs/src/index");
import React, { useEffect, useState } from "react";
import Tabular from "../../../components/Tabular/Tabular";
import styles from "../../MerchantTabular/MerchantTabular.scss";
import mStyles from "../../MerchantRow/MerchantRowDesktop/MerchantRow.scss";
import ROUTE_PATHS from "Routes/routeConstants";
import { getInitialData } from "../helpers/initial";
import McRow from "../../MerchantRow/MerchantRowDesktop/McRow";
import { RowContextProvider } from "../../../../contexts/RowContext";
import Searchbar from "../../MerchantTabular/common/Searchbar";
import { merchantTabNameEnums, merchantTabs, queryTabNameEnums, TABS_TO_QUERY } from "../../MerchantTabular/constants";
import DaVinciService from "../../../../services/davinciService";
import { DAVINCI_PAGE_MERCHANT, FIRSAT_CADIRI_MERCHANT_ID } from "Utils/constants";
import { deleteAllQueryParamsWithException, updateQueryParams } from "Utils/helpers/browser";
import { setWebtrekkConfig } from "../../../../services/webtrekkService";

const MerchantHeaderDesktop = ({ initialState }) => {
  const [filteredProductCount, setFilteredProductCount] = useState();
  const [filteredParameter, setFilteredParameter] = useState();
  const { merchantDetail, showLayoutTab, showAdvantageTab, showSuperTab, merchantId } = initialState.data;
  const davinci = DaVinciService(merchantDetail, DAVINCI_PAGE_MERCHANT);

  const [isTab, setIsTab] = useState(initialState.data.activeTab);

  useEffect(() => {
    window?.HbEventBus?.on("storefront.voltran.analytics.utagData.override", data => {
      setFilteredParameter(data?.search_context?.originalSearchTerm);
      setFilteredProductCount(data?.qty_search_result);
    });

    HBEventBusService().onTabChangeListen(data => {
      setIsTab(data.tabName);
      const queryParams = new URLSearchParams(window.location.search);
      if (data.tabName === merchantTabNameEnums.MERCHANT_ROW) {
        deleteAllQueryParamsWithException("", queryParams);
        return;
      }
      if (data.tabName === merchantTabNameEnums.ABOUT_SELLER || data.tabName === merchantTabNameEnums.SUPER_TAB) {
        deleteAllQueryParamsWithException("tab", queryParams);
      }
      updateQueryParams({ tab: TABS_TO_QUERY[data.tabName] }, queryParams);
    });

    setWebtrekkConfig();

    sessionStorage.removeItem(queryTabNameEnums.ADVANTAGE_PRODUCTS);
    sessionStorage.removeItem(queryTabNameEnums.ALL_PRODUCTS);
  }, []);

  const getSessionStorageFilters = key => {
    if (sessionStorage.getItem(key)) {
      const filter = JSON.parse(sessionStorage.getItem(key));
      window.history.replaceState("", "", `${window.location.pathname}?${filter?.query?.toString()}`);
    } else {
      const queryParams = new URLSearchParams(window.location.search);
      deleteAllQueryParamsWithException("tab", queryParams);
    }
  };

  const setSessionStorageFilters = key => {
    const queryParams = new URLSearchParams(window.location.search);
    sessionStorage.setItem(key, JSON.stringify({ query: queryParams.toString() }));
  };

  const onTabChange = tab => {
    if (isTab === merchantTabNameEnums.ADVANTAGE_PRODUCTS) {
      setSessionStorageFilters(queryTabNameEnums.ADVANTAGE_PRODUCTS);
    }
    if (isTab === merchantTabNameEnums.MORDOR_PRODUCTS) {
      setSessionStorageFilters(queryTabNameEnums.ALL_PRODUCTS);
    }
    if (tab.name === merchantTabNameEnums.ADVANTAGE_PRODUCTS) {
      getSessionStorageFilters(queryTabNameEnums.ADVANTAGE_PRODUCTS);
    }
    if (tab.name === merchantTabNameEnums.MORDOR_PRODUCTS) {
      getSessionStorageFilters(queryTabNameEnums.ALL_PRODUCTS);
    }

    HBEventBusService().onTabChange(tab.name);
    davinci.tabChangeEvent(tab.label);
  };

  let filteredMerchantTabs = merchantTabs;
  if (merchantId === FIRSAT_CADIRI_MERCHANT_ID) {
    filteredMerchantTabs = filteredMerchantTabs.filter(tab => tab.name !== merchantTabNameEnums.ABOUT_SELLER);
  }
  if (!showSuperTab) {
    filteredMerchantTabs = filteredMerchantTabs.filter(tab => tab.name !== merchantTabNameEnums.SUPER_TAB);
  }
  if (!showAdvantageTab) {
    filteredMerchantTabs = filteredMerchantTabs.filter(tab => tab.name !== merchantTabNameEnums.ADVANTAGE_PRODUCTS);
  }
  return (
    <RowContextProvider>
      <div className={[styles.Wrapper, mStyles.wrapper].join(" ")}>
        <McRow initialState={initialState} styles={mStyles} filteredProductCount={filteredProductCount} />
        <div className={styles.TabularWrapper}>
          <div className={styles.Tabular}>
            <Tabular
              tabs={
                showLayoutTab && showLayoutTab === "false"
                  ? filteredMerchantTabs.filter(tab => tab.name !== merchantTabNameEnums.MERCHANT_ROW)
                  : filteredMerchantTabs
              }
              onChange={onTabChange}
              value={isTab}
            />
          </div>
          <div className={styles.Searchbar}>
            <Searchbar initial={filteredParameter} />
          </div>
        </div>
      </div>
    </RowContextProvider>
  );
};

const component = voltran.default.withBaseComponent(MerchantHeaderDesktop, ROUTE_PATHS.MERCHANT_HEADER);

component.services = [voltran.default.SERVICES.merchantContentApi];

component.getInitialState = getInitialData;
component.getSeoState = initialState => {
  const { activeTab } = initialState;
  return { defaultTab: activeTab };
};
export default component;
